<template>
  <div class="app-container">
    <el-form style="margin-left: 50px" label-width="120px">
      <el-form-item label="产品列表:">
        <template>
          <div class="product_item">
            <div class="img">
              <img :src="JSON.parse(form.product_snap).cover" alt="" />
            </div>
            <div class="text">
              <div class="title">
                <p style="width: 95%">
                  {{ JSON.parse(form.product_snap).name }}
                </p>
                <div class="sum">x{{ form.num }}</div>
              </div>
              <div class="amount">
                {{ JSON.parse(form.product_snap).product_specs }}
              </div>
              <div class="price">
                ¥{{ JSON.parse(form.product_specs_combination_snap).price }}
              </div>
              <div class="reject" v-if="form.product_child_order_refund">
                <p v-if="form.product_child_order_refund.examine_status == 3">
                  驳回理由:{{ form.product_child_order_refund.refuse_reason }}
                </p>
                <p
                  v-else-if="
                    form.product_child_order_refund.examine_status == 1
                  "
                  style="color: #e6a23c"
                >
                  售后中
                </p>
                <p v-else style="color: #42b983">售后成功</p>
              </div>
              <!-- <el-button
                v-if="
                  form.status == 2 &&
                  form.refund_status != 1 &&
                  form.refund_status != 2
                "
                type="danger"
                class="btn"
                @click="salesReturn(form.order_no)"
              >
                退货处理
              </el-button> -->
            </div>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="用户名称:">
        {{ form.user ? form.user.nickname : "-" }}
      </el-form-item>
      <el-form-item label="头像:">
        <img
          :src="form.user ? form.user.avatar : ''"
          alt=""
          style="width: 80px; height: 80px"
        />
      </el-form-item>
      <el-form-item label="收件人:">
        <!-- {{ JSON.parse(form.user_receiving_address_snap) }} -->
        {{ JSON.parse(form.parent_order.user_receiving_address_snap).contact }}
      </el-form-item>
      <el-form-item label="收货人电话:">
        {{
          JSON.parse(form.parent_order.user_receiving_address_snap)
            .contact_phone
        }}
      </el-form-item>
      <el-form-item label="收货地址:">
        {{
          JSON.parse(form.parent_order.user_receiving_address_snap).province
            .name
        }}
        {{
          JSON.parse(form.parent_order.user_receiving_address_snap).city.name
        }}
        {{
          JSON.parse(form.parent_order.user_receiving_address_snap).area.name
        }}
      </el-form-item>
      <el-form-item label="详细地址:">
        {{
          JSON.parse(form.parent_order.user_receiving_address_snap)
            .detail_address
        }}
      </el-form-item>
      <el-form-item label="留言:">
        {{ form.remarks }}
      </el-form-item>
      <!-- <el-form-item label="运费:">
        {{ form.distribution_price }}
      </el-form-item> -->

      <el-form-item label="总价:">
        {{ form.total_price }}
      </el-form-item>

      <el-form-item label="订单编号:">
        {{ form.order_no }}
      </el-form-item>
      <el-form-item label="下单时间:">
        {{ form.parent_order.created_at }}
      </el-form-item>
      <el-form-item label="支付时间:">
        {{ form.parent_order.pay_at }}
      </el-form-item>
      <el-form-item label="售后:" v-if="form.order_refund">
        {{ form.order_refund.content }}
      </el-form-item>
      <el-form-item
        v-if="form.order_refund && form.order_refund.images.length > 0"
      >
        <el-image
          style="width: 400px; height: 200px; margin-right: 10px"
          v-for="(item, i) in form.order_refund.images"
          :key="i"
          :src="item"
          :preview-src-list="form.order_refund.images"
        >
        </el-image>
      </el-form-item> 
      <el-form-item
        label="驳回原因:"
        v-if="form.order_refund && form.order_refund.refuse_reason"
      >
        {{ form.order_refund.refuse_reason }}
      </el-form-item>
      <el-form-item label="完成时间:">
        {{ form.finish_at ? form.finish_at : "-" }}
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>

        <template v-if="form.refund_status == 1">
          <el-button
            type="danger"
            style="margin-left: 10px"
            :loading="btnLoading"
            @click="Refund"
          >
            驳回
          </el-button>
          <el-button
            type="success"
            style="margin-left: 10px"
            :loading="btnLoading"
            @click="pass"
          >
            通过
          </el-button>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      btnLoading: false,
      form: {},
      id: "",
      rules: {},
    };
  },
  created() {
    this.id = this.$route.query.order;
    this.getDetail();
  },
  mounted() {},
  methods: {
    pass() {
      this.btnLoading = true;
      this.$confirm("此操作将通过该用户的退货, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/productOrder/refundPass",
            method: "post",
            params: {
              order_no: this.form.order_no,
            },
          }).then((response) => {
            this.btnLoading = false;
            this.$message({
              type: "success",
              message: "通过成功!",
            });
            this.$router.go(-1);
          });
        })
        .catch(() => {
          this.btnLoading = false;
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    Refund() {
      this.btnLoading = true;
      this.$prompt("请输入驳回理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          request({
            url: "/api/backend/productOrder/refund",
            method: "post",
            params: {
              order_no: this.form.order_no,
              refuse_reason: value,
            },
          }).then((response) => {
            this.btnLoading = false;
            this.$message({
              type: "success",
              message: "驳回成功!",
            });
            // this.getDetail(this.order);
            this.$router.go(-1);
          });
        })
        .catch(() => {
          this.btnLoading = false;
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    salesReturn(order) {
      this.$router.push(`/order/details?order=${order}`);
    },
    getDetail() {
      request({
        url: "/api/backend/productOrder/detail",
        method: "get",
        params: {
          id: this.id,
        },
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
        }
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/settings/store",
            method: "post",
            data: this.form,
          })
            .then(() => {
              this.btnLoading = false;
              this.getDetail();
              this.$message({
                type: "success",
                message: "操作成功",
              });
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input {
  width: 300px;
}
.product_item {
  border: 1px solid #999999;
  padding: 10px;
  width: 40%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 20px;
  .img {
    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    position: relative;
    flex: 1;
    margin-left: 10px;
    .title {
      position: relative;
      font-weight: 700;
      .sum {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .amount {
      color: #333333;
    }

    .price,
    .reject {
      color: red;
    }
  }
}
.color999 {
  color: #999999;
  font-size: 14px;
}
.tips {
  color: #0375e8;
  margin-top: 20px;
}
</style>